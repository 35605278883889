import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import './assets/main.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faYoutube, faInstagram, faDiscord, faTiktok, } from '@fortawesome/free-brands-svg-icons'
import { faAngleDown, faGaugeHigh, faBolt, faLock, faAngleLeft, faAngleRight, faCode, faUser} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faYoutube, faInstagram, faDiscord, faAngleDown, faGaugeHigh, faBolt, faLock, faAngleLeft, faAngleRight, faTiktok, faCode, faUser );

Vue.component('font-awesome-icon', FontAwesomeIcon)

import 'aos/dist/aos.css';
import AOS from "aos";

new Vue({
  router,
  render: h => h(App),
  mounted() {
    AOS.init({
      startEvent: 'load',
    });
  }
}).$mount('#app')
