<template>
  <div class="is-relative" style="overflow: hidden" id='website'>
    <Navbar></Navbar>
<!--    <div :class="['darkLayer', {'active' : dark}]"></div>-->
<!--    <div class="decorationContainer">-->
<!--    </div>-->
<!--    <Header @dark="changeDark"></Header>-->
<!--    <section class="section" id="zaufaliNam" data-aos="fade-down">-->
<!--      <div class="container has-text-centered">-->
<!--        <h1 class="sectionTitle mb-6">Zaufali nam</h1>-->
<!--        <div class="is-flex is-justify-content-center">-->
<!--          <Carousel :items="items"></Carousel>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
    <section class="section">
      <div class="container has-text-centered">
        <h1 class="sectionTitle mb-6">Portfolio</h1>
        <div class="columns is-multiline">
          <div class="column is-3">
            <PortfolioCard title="Pimpus.ml" description="Reklama stworzona dla strony internetowej i discorda pimpus.ml" image="https://pimpus.ml/pimpus.png" url="https://portfolio.pimpus.ml/pimpusml.mp4"/>
          </div>
          <div class="column is-3">
            <PortfolioCard title="Eturn" description="Reklama stworzona dla bota discord Eturn " image="https://portfolio.pimpus.ml/img/eturn.png" url="https://portfolio.pimpus.ml/filmy/eturn.mp4" />
          </div>
          <div class="column is-3">
            <PortfolioCard title="visualcontent.pl" description="Praca stworzona na potrzebę projektu visualcontent.pl. Stworzona reklama. " image="https://portfolio.pimpus.ml/img/vis.png" url="https://portfolio.pimpus.ml/filmy/vis.mp4" />
          </div>
          <div class="column is-3">
            <PortfolioCard title="ShizeClone.eu" description="Reklama stworzona dla ShizeClone.eu " image="https://portfolio.pimpus.ml/img/shizeclone.png" url="https://portfolio.pimpus.ml/filmy/shizeclone.mp4" />
          </div>
          <div class="column is-3">
            <PortfolioCard title="discordy.pl" description="Reklama stworzona dla strony internetowej, która udostępnia skrypty, boty itp. " image="https://portfolio.pimpus.ml/img/discordypl.png" url="https://portfolio.pimpus.ml/filmy/discordypl.mp4" />
          </div>
        </div>
      </div>
    </section>

    <section style="margin-bottom: 6rem" data-aos="zoom-in">
      <div class="container has-text-centered">
        <a class="btn" :href="link=('https://discord.com/users/544202602724720648')" target="_blank">Złóż zamówienie!</a>
      </div>
    </section>
    <Footer name="pimpus.ml" color="#fad61d"></Footer>
  </div>
</template>

<script>
// import Header from '@/components/Header'
// import Carousel from '../components/Carousel.vue'
import Footer from '../components/Footer.vue'
import { getENV } from '@/utils.js'
import PortfolioCard from "../components/PortfolioCard";
import Navbar from "../components/Navbar";

export default {
  name: 'Home',
  components: {
    PortfolioCard,
    // Header,
    // Carousel,
    Footer,
    Navbar
  }, data() {
    return {
      items: [
        {img: 'https://avatars.githubusercontent.com/u/91021785?v=4?s=400', name: 'Jasio'},
        {img: 'https:pimpus.ml/pimpus.png', name: 'Twoja nazwa'},
        {img: 'https:pimpus.ml/pimpus.png', name: 'Twoja nazwa'},
        {img: 'https:pimpus.ml/pimpus.png', name: 'Twoja nazwa'},
        {img: 'https:pimpus.ml/pimpus.png', name: 'Twoja nazwa'},
      ], dark: false
    }
  }, methods: {
    changeDark(value) {
      this.dark = value;
    }, getENV
  }
}
</script>

<style scoped lang="scss">
@import "~bulma/sass/utilities/mixins";

#website {
  background-color: #1b1b1b;
  min-height: 100vh;
}

.decorationContainer {
  position: absolute;
  width: 1800px;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  bottom: 0;
}

.botInfo {
  display: block;
  .iconn {
    font-size: 2rem;
    color: var(--opinia_icon)
  }

  .header-opinia {
    color: var(--primary);
    font-size: 1.5rem;
    font-weight: 800;
  }

  .desc-opinia {
    color: #ffffff;
    font-weight: 400;
    font-size: 1.05rem;
  }
}

.aboutDev {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;

  @include touch {
    gap: 25px;
    flex-direction: column;
  }
  // margin-left: auto;
  // margin-right: auto;

  img {
    border-radius: 50%;
    border: var(--primary) solid 3px;
    height: 200px;

    @include touch {
      // height: clamp(100px, 15vw, 175px);
      height: clamp(120px, 20vw, 175px);
      // height: 18vw;
    }
  }

  span {
      white-space: pre-wrap;
      color: #fff;
      font-size: 1.2rem;
      font-weight: 600;

      @include touch {
        font-size: clamp(1rem, 3vw, 1.25rem);
        // font-size: 2vw;
        // font-size: 1.25rem;
        // font-size: 1rem;
      }
  }

  a {
    transition: color .2s;

    &:hover {
      color: var(--primary)
    }
  }
}

.section {
  margin-bottom: 15rem;
  // z-index: 0;
  // position: relative;

  @include touch {
    margin-bottom: 5rem;
  }
}

.sectionTitle {
  font-size: clamp(2rem, 8vw, 2.5rem);
  color: #fff;
  font-weight: 800;
  // margin-bottom: 1rem;
  // font-size: 8vw;
  // font-size: 2.5rem;
  // font-size: 2rem;
}

.darkLayer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -5;

  backdrop-filter: brightness(100%) opacity(0);

  transition: z-index .5s step-end, backdrop-filter .5s linear;

  &.active {
    z-index: 3;
    backdrop-filter: brightness(50%) opacity(1);

    transition: z-index .5s step-start, backdrop-filter .5s linear;
  }
}
</style>
