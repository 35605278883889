<template>
  <div class="portfolioCard" @click="openPage">
    <img :src="image" style="width: 100%; height: 200px; border-radius: 4px; object-fit: cover;" />
    <h3 class="portfolioTitle">{{title}}</h3>
    <span class="portfolioDescription">{{ description }}</span>
  </div>
</template>

<script>
export default {
  name: "PortfolioCard",
  props: {
    image: String,
    url: String,
    title: String,
    description: String
  }, methods: {
    openPage() {
      window.open(this.url)
    }
  }
}
</script>

<style scoped>
.portfolioCard {
  background-color: #242424;
  border-radius: 12px;
  width: 100%;
  padding: 10px 12px;
  transition: all .6s ease;
  cursor: pointer;
  text-align: left;
}

.portfolioCard:hover {
  transform: scale(1.20);
}

.portfolioTitle {
  font-size: 1.5rem;
  color: #bb47ff;
  font-weight: 500;
}

.portfolioDescription {
  font-size: 1.1rem; color: #fff; font-weight: 300; opacity: .9;
}
</style>
