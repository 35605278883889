<template>
  <div class="navv">
    <h1 class="heading-text"><span class="pimpusml">Pimpus.ml</span></h1>
    <div class="info">
      <div :class="['tongue', {'active': active}]">
        <ul class="navItems">
          <li><a class="navLink" :href="link=('https://pimpus.ml')">Strona główna</a></li>
          <li><a class="navLink" :href="link=('https://portfolio.pimpus.ml')">Portfolio</a></li>
          <li><a class="navLink" :href="link=('https://pimpus.ml/wiadomosci')">Wiadomości</a></li>
        </ul>
      </div>
    </div>
    <div class="lines" @click="active = !active">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
import { getENV, scrollTo } from '@/utils.js'

export default {
  data() {
    return {
      active: false
    }
  }, methods: {
    getENV,
    preventScroll(e){
      e.preventDefault();
      e.stopPropagation();
      return false;
    }, scroll(destination) {
      document.removeEventListener('touchmove', this.preventScroll, { passive: false });
      scrollTo(destination);
      this.active = false;
    }
  }, watch: {
    active(value) {
      this.$emit('dark', value)
      if(value) document.addEventListener('touchmove', this.preventScroll, { passive:false });
      else document.removeEventListener('touchmove', this.preventScroll, { passive: false });
    }
  }
}
</script>
<script>
  import { getENV, scrollTo } from '@/utils.js'
  export default {
    data() {
      return {
        active: false
      }
    }, methods: {
      getENV,
      preventScroll(e){
        e.preventDefault();
        e.stopPropagation();
        return false;
      }, scroll(destination) {
        document.removeEventListener('touchmove', this.preventScroll, { passive: false });
        scrollTo(destination);
        this.active = false;
      }
    }, watch: {
      active(value) {
        this.$emit('dark', value)
        if(value) document.addEventListener('touchmove', this.preventScroll, { passive:false });
        else document.removeEventListener('touchmove', this.preventScroll, { passive: false });
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  @import "~bulma/sass/utilities/mixins";
  .logo, .lines {
    z-index: 7;
  }
  .navv {
      padding: 40px 5px;
      display: flex;
      align-items: center;
      width: 101%;
      @include until-fullhd {
        padding: 40px 75px;
      }
    
      @include until(1600px) {
        padding: 40px 35px;
      }
      // @include until-widescreen {
      //   padding: 40px 5px;
      // }
  }
  .info {
    margin-left: auto;
    @include until-widescreen {
      display: flex;
      align-items: center;
      justify-content: center;
      // position: relative;
      // width: 100%;
    }
  }

.tongue {
  @include until-widescreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 180px;
    background-color: #1b1b1b;
    border-radius: 0 0 113px 113px;
    z-index: 6;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    transition: transform 3.5s;

    transform: translateY(-100%);
  }

  &.active {
    transform: translateY(-5%);
  }
}

.navItems {
  display: inline-flex;
  align-items: center;

  @include until-widescreen {
    display: block;
    padding-bottom: 10px;
  }
}

.navItems > li {
  display: inline;
  padding: 0 25px;
  font-weight: 600;

  @include until-fullhd {
    display: block;
    padding: 0 20px;
    text-align: center;
  }

  @include until-widescreen {
    display: block;
    padding: 5px 0;
    text-align: center;
  }
}

.navLink {
  color: var(--primary);
  transition: color .5s;
  font-weight: 600;
  font-size: 0.95rem;

  @include until-widescreen {
    font-size: 1.3rem;
    color: rgb(140, 0, 255);
  }
}

.pimpusLink:hover {
  color: rgb(140, 0, 255);
}

.pimpusLink {
  color: var(--pimpusml);
  transition: color .5s;
  font-weight: 700;
  font-size: 0.95rem;

  @include until-widescreen {
    font-size: 1.3rem;
    color: rgb(140, 0, 255);
  }
}

.navLink {
  color: var(--primary);
  transition: color .5s;
  font-weight: 600;
  font-size: 0.95rem;

  @include until-widescreen {
    font-size: 1.3rem;
    color: rgb(140, 0, 255);
  }
}

.navLink:hover {
  color: rgb(255, 255, 255);
}


.btnn {
  padding: 8px 40px;
  background-color: rgb(0, 0, 0);
  color: #000000;
  font-weight: 800;
  font-size: 1.1rem;
  border-radius: 16px;

  transition: background-color .5s;

  &:hover {
    background-color: var(--primary);
    color: rgb(0, 0, 0);
  }

  @include until-widescreen {
    background-color: var(--primary);
    color: rgb(0, 0, 0);
  }
}

.heading-text {
    font-size: 2rem;
    color: rgb(255, 255, 255);
    font-weight: 750;
    font-family: "Poppins", sans-serif;
    width: 50%;

    @include touch {
        // font-size: 1.25rem;
        // font-size: 1.5rem;
        // font-size: 2vw;
        width: 50%;
        font-size: clamp(1.5rem, 4vw, 2rem);
    }

    @include mobile {
        width: 100%;
    }
}

.lines {
  visibility: hidden;
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;

  @include until-widescreen {
    visibility: visible;
  }

  .line {
    background-color: rgb(162, 0, 255);
    width: 30px;
    height: 5px;
    border-radius: 16px;
  }
}
</style>
